import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import DrawerContent from "components/Drawer";
import { styled, Button } from "@mui/material";

const drawerWidth = 240;
interface LayoutProps {
  children: React.ReactElement;
}
const Main = styled("div", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  background: theme.palette.grid.main,
  height: `calc( 100vh - ${theme.spacing(12)} )`,
  padding: theme.spacing(6, 8),
  position: "relative",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  [theme.breakpoints.up("sm")]: {
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
  [theme.breakpoints.down("sm")]: {
    height: `calc( 100vh - ${theme.spacing(6)} )`,
    padding: theme.spacing(3, 5),
  },
}));
const DrawerExpander = styled(Button, {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: "absolute",
  left: -4,
  padding: 14,
  minWidth: "unset",
  width: "unset",
  top: "24%",
  border: `1px solid ${theme.palette.border.main}`,
  background: "white",
  borderRadius: "unset",
  borderTopRightRadius: "50%",
  borderBottomRightRadius: "50%",
  boxShadow: "0px 1px 1px #0000000D",
  [theme.breakpoints.down("sm")]: {
    top: "2%",
  },
}));

function Layout({ children }: LayoutProps) {
  const [open, setOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <DrawerContent />
        </Drawer>
        <Drawer
          PaperProps={{ id: "paper-props" }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerContent />
        </Drawer>
      </Box>

      <Main open={open}>
        <DrawerExpander variant="text" onClick={handleDrawerToggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.819"
            height="11.433"
            viewBox="0 0 6.819 11.433"
          >
            <path
              id="icons8-expand-arrow"
              d="M12.085,14.133,8.206,10.255a1.077,1.077,0,0,0-1.523,0h0a1.077,1.077,0,0,0,0,1.523l4.684,4.684a1.014,1.014,0,0,0,1.435,0l4.684-4.684a1.077,1.077,0,0,0,0-1.523h0a1.077,1.077,0,0,0-1.523,0Z"
              transform="translate(16.759 -6.368) rotate(90)"
              fill="#a2aec2"
            />
          </svg>
        </DrawerExpander>
        {children}
      </Main>
    </Box>
  );
}

export default Layout;
