import { Select, MenuItem } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const AloTechSelect = styled(Select)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    color: "white",
    fontSize: 32,
  },
  "& .MuiInputBase-input": {
    fontWeight: 600,
    border: "2px solid #74d6f029",
    color: "white",
    background: theme.palette.drawerText.light,
    "&:focus": {
      border: "2px solid #74d6f029",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function AccountSelect() {
  const [value, setValue] = React.useState(10);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <AloTechSelect
      fullWidth
      IconComponent={KeyboardArrowDownIcon}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="Age"
      onChange={handleChange}
    >
      <MenuItem value={10}>
        Account
        <br /> Executive
      </MenuItem>
      <MenuItem value={20}>
        Account
        <br /> Manager
      </MenuItem>
      <MenuItem value={30}>
        Account
        <br /> Staff
      </MenuItem>
    </AloTechSelect>
  );
}

export default AccountSelect;
