import { ReactElement } from "react";
interface AuthGuardProps {
  children: ReactElement;
}

function AuthGuard({ children }: AuthGuardProps) {
  // This guard is checking if the user is logged in.
  // const account = useSelector(state => state.account);

  // if (!account.user) {
  //     return <Redirect to="/login" />;
  // }
  return children;
}

export default AuthGuard;
