import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { PaletteColor, ThemeOptions, PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    border: PaletteColor;
    drawerText: PaletteColor;
    menu: PaletteColor;
    grid: PaletteColor;
  }
  interface PaletteOptions {
    drawerText: PaletteColorOptions;
    border: PaletteColorOptions;
    menu: PaletteColorOptions;
    grid: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    drawerText: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    radius: true;
  }
}

const customTypo = {
  body1: {
    color: "#6d809f",
    fontSize: 16,
  },
  body2: {
    color: "#6d809f",
    fontSize: 14,
    fontWeight: 500,
  },
  caption: {
    color: "#002983",
  },
  h1: {
    fontSize: 36,
    fontWeight: 600,
    color: "#002983",
  },
  h2: {
    color: "#002983",
  },
  h3: {
    color: "#002983",
  },
  h4: {
    color: "#002983",
  },
  h5: {
    color: "#262626",
  },
  h6: {
    color: "#262626",
  },

  subtitle1: {
    color: "#6d809f",
    fontSize: 16,
  },
  subtitle2: {
    color: "#9bacc9",
    fontSize: 12,
    fontWeight: 600,
  },

  useNextVariants: true,
};
const options: ThemeOptions = {
  breakpoints: {
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xl: 1905,
      xs: 0,
    },
  },
  palette: {
    grid: {
      main: "#EAF2F4",
    },
    border: {
      main: "#e4e7f2",
    },
    drawerText: {
      main: "#9bacc9",
      light: "#cfeff70d",
    },
    menu: {
      main: "#314469",
    },
    primary: {
      main: "#1e3772",
    },
    secondary: {
      main: "#fd741f",
    },
    text: {
      primary: "#6d809f",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: customTypo,
};

let tempTheme = createTheme({
  ...options,
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: ({ theme }) => ({
          margin: theme.spacing(0, 0),
        }),
        ol: ({ theme }) => ({
          ...theme.typography.body2,
        }),
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "radius" },
          style: {
            textTransform: "none",
            border: `1px solid red`,
          },
        },
        {
          props: { variant: "radius", color: "secondary" },
          style: {
            padding: "4px 16px",
            fontSize: 14,
            color: "#032983",
            borderRadius: 200,
            boxShadow: "0px 2px 4px #00000027",
            border: "2px solid #FD7422",
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "unset",
          padding: theme.spacing(1, 2),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1),
          border: `solid 1px #E4E7F2`,
          boxShadow: "0px 20px 40px #0000000D",
          padding: theme.spacing(2, 1),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 14,
          color: theme.palette.menu.main,
          padding: theme.spacing(1.5, 3),
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }) => ({
          padding: theme.spacing(0, 2),
          background: theme.palette.primary.main,
          border: "unset",
          borderRadius: "unset",
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          outlined: "unset",
          border: "unset",
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 48,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: ({ theme }) => ({
          ...theme.typography.body2,
          color: theme.palette.menu.main,
        }),
        secondary: ({ theme }) => ({
          ...theme.typography.subtitle2,
          color: theme.palette.drawerText.main,
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2, 3, 2, 5),
        }),
      },
    },
  },
});

tempTheme = {
  ...tempTheme,
};

const Theme = ({ children }: any) => (
  <ThemeProvider theme={tempTheme}>{children}</ThemeProvider>
);
export default Theme;
