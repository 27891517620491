import AuthGuard from "components/AuthGuard";
import { lazy } from "react";
const Home = lazy(() => import("pages/Home"));
const Login = lazy(() => import("pages/Login"));

const routes = {
  Home: {
    element: Home,
    path: "/",
    guard: AuthGuard,
  },
  Login: {
    element: Login,
    path: "/login",
  },
};
export default routes;
