import { ReactComponent as Phone } from "assets/images/drawer/phone.svg";
import { ReactComponent as Chat } from "assets/images/drawer/chat.svg";
import { ReactComponent as Sms } from "assets/images/drawer/sms.svg";
import { ReactComponent as Webhook } from "assets/images/drawer/webhook.svg";
import { ReactComponent as Users } from "assets/images/drawer/users.svg";
import { ReactComponent as Guide } from "assets/images/drawer/guide.svg";
import { ReactComponent as Control } from "assets/images/drawer/control.svg";
import { ReactComponent as IpPhone } from "assets/images/drawer/ipphone.svg";
import { ReactComponent as App } from "assets/images/drawer/app.svg";
import { ReactComponent as Locations } from "assets/images/drawer/locations.svg";
const menu = [
  {
    logo: Phone,
    text: "Call",
    to: "/",
  },
  {
    logo: Chat,
    text: "Chat",
    to: "/chat",
  },
  {
    logo: Sms,
    text: "SMS",
    to: "/sms",
  },
  {
    logo: Webhook,
    text: "WebHook",
    to: "/web-hook",
  },
  {
    logo: Users,
    text: "Users",
    to: "/users",
  },
  {
    logo: Guide,
    text: "Guide",
    to: "/guide",
  },
  {
    logo: Control,
    text: "Control",
    to: "/control",
  },
  {
    logo: IpPhone,
    text: "IP Phone",
    to: "/ip-phone",
  },
  {
    logo: App,
    text: "App",
    to: "/app",
  },
  {
    logo: Locations,
    text: "Locations",
    to: "/locations",
  },
];

export default menu;
