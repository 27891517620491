import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Typography } from "@mui/material";
import logo from "assets/images/logo.png";
import menu from "./menu";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import AccountSelect from "components/AccountSelect";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "center",
}));
const NavigationDot = styled("div")(({ theme }) => ({
  display: "block",
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: theme.spacing(1),
  background: theme.palette.secondary.main,
  position: "absolute",
  left: theme.spacing(3),
}));

export default function DrawerContent() {
  const theme = useTheme();
  const location = useLocation();
  let activeStyle = {
    background: theme.palette.drawerText.light,
  };
  let activeStyleText = {
    color: theme.palette.secondary.main,
  };
  return (
    <>
      <DrawerHeader>
        <Box mt={6} mb={4} component={Link} to={"/"}>
          <img height="48" src={logo} alt="alo tech" />
        </Box>
      </DrawerHeader>

      <List>
        <Box mb={6}>
          <AccountSelect />
        </Box>
        {menu.map(({ logo: Logo, text, to }, index) => (
          <ListItem
            button
            key={index}
            component={Link}
            to={to}
            style={location.pathname === to ? activeStyle : {}}
          >
            {location.pathname === to && <NavigationDot />}
            <ListItemIcon>{<Logo />}</ListItemIcon>
            <ListItemText disableTypography>
              <Typography
                variant="subtitle2"
                color="drawerText"
                style={location.pathname === to ? activeStyleText : {}}
              >
                {text}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
}
