import Router from "router";
import Theme from "theme";
import "assets/css/app.css";

function App() {
  return (
    <Theme>
      <Router />
    </Theme>
  );
}

export default App;
