import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Fragment, Suspense } from "react";
import Layout from "components/Layout";
import routes from "router/routes";
import _ from "lodash";

const renderRoutes = (routes) => {
  return _.map(routes, (route) => {
    const Component = route.element;
    const Guard = route.guard || Fragment;
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Suspense fallback={<></>}>
            <Guard>
              {route.routes ? renderRoutes(route.routes) : <Component />}
            </Guard>
          </Suspense>
        }
      />
    );
  });
};

function Router() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>{renderRoutes(routes)}</Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default Router;
